/* .assistant-font {
  font-family: "Assistant", sans-serif; 
  font-weight: lighter;
  font-style: normal;
} */

.assistant-font {
     font-family: "Grey Qo", cursive;
     font-weight: 400;
     font-style: normal;
}

.ibm-plex-sans-thin {
     font-family: "IBM Plex Sans", sans-serif;
     font-weight: 100;
   }