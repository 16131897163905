@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom breakpoint at 500px */
@media (min-width: 450px) {
     .element-500\:h-400 {
          height: 130px;
     }

     .element-500\:w-400 {
          width: 130px;
     }
}

::-webkit-scrollbar {
     width: 2px;
     background: rgb(255, 255, 255);
}

@keyframes expandWidth {
     from {
          width: 0;
     }

     to {
          width: 300px;
     }
}

.example::-webkit-scrollbar {
     display: none;
}

.example {
     -ms-overflow-style: none;
     scrollbar-width: none;
}

.expand-width {
     animation: expandWidth 1s ease-out forwards;
}

.font {
     font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.fontTitle {
     font-family: medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif
}

.font_2 {
     font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif
}

.jodit-status-bar-link {
     display: none;
}

.jodit-wysiwyg {
     background-color: #f7f4ed;
     padding: 10px;
}

.jodit-ui-group {
     background-color: #f7f4ed;
}

.jodit-status-bar_resize-handle_true {
     background-color: #f7f4ed;
}